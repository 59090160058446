import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";

// Custom row styling with alternate color and reduced height
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // borderCollapse: "collapse",
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(224, 224, 224, 1)",
  },
  "& td, & th": {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: "2px", // Set padding to 2px
    fontSize: "13px", // Set font size to 13px
    textAlign: "center",
  },
}));

const detailColumns = [
  { label: "Part Name", key: "partName", width: "150px" },
  { label: "Missing", key: "missing" },
  { label: "Damaged", key: "damaged" },
  { label: "Weight", key: "weight" },
  { label: "Quantity", key: "quantity" },
  { label: "Rate", key: "rate" },
  { label: "Amount", key: "amount" },
  { label: "CGST", key: "cgst" },
  { label: "SGST", key: "sgst" },
  { label: "Total", key: "total" },
  { label: "Salvage Amount", key: "salvageAmount" },
  { label: "Slvg CGST", key: "slvgCgst" },
  { label: "Slvg SGST", key: "slvgSgst" },
  { label: "Total Slvg", key: "totalSlvg" },
  { label: "Net Amount", key: "netAmount" },
];

const quantityColumns = [
  { label: "Part Name", key: "partName", width: "150px" },
  { label: "Missing", key: "missing" },
  { label: "Damaged", key: "damaged" },
  { label: "Weight", key: "weight" },
  { label: "Quantity", key: "quantity" },
];

const amountColumns = [
  { label: "Part Name", key: "partName", width: "150px" },
  { label: "Missing", key: "missing" },
  { label: "Damaged", key: "damaged" },
  { label: "Weight", key: "weight" },
  { label: "Quantity", key: "quantity" },
  { label: "Rate", key: "rate" },
  { label: "Amount", key: "amount" },
  { label: "CGST", key: "cgst" },
  { label: "SGST", key: "sgst" },
  { label: "Total", key: "total" },
];

const EditEstimate = () => {
  // State to hold the input values
  const [inputStates, setInputStates] = useState({
    columnType: 3,
    siRate: 0,
    percentSIR: 0,
    repairNo:"none",
  });
  const [columns, setColumns] = useState(detailColumns);
  const [circlesData, setCirclesData] = useState([]);
  const [capacityData, setCapacityData] = useState([]);
  const [allLOTData, setAllLOTData] = useState([]);
  const [LOTData, setLOTData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);
  const [isNew, setIsNew] = useState(false);
  // Initialize state for editable fields
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const params = useParams();

  // General handler to update state for both TextField and Autocomplete
  const handleLotsChange = (key, value) => {
    setInputStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchCirclesData = () => {
    let SQLQuery = { query: `select id, name  from circles where alloted = 1` };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setCirclesData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchCapacityData = () => {
    let SQLQuery = {
      query: `select id, transformerCapacity  from transformerTypes`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setCapacityData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchAllLOTData = () => {
    let SQLQuery = {
      query: `SELECT tcl.id, tcl.circleId, tcl.lotNo, tcl.mostTransformersCapacity, tt.storeIssueRate, tt.id as transformerTypesId,  tt.transformerCapacity FROM transactionControlLots tcl LEFT JOIN transformerTypes tt ON CAST(tcl.mostTransformersCapacity AS UNSIGNED) = tt.id WHERE tcl.circleId = ${inputStates?.circleId}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setAllLOTData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchLOTDataById = () => {
    let SQLQuery = {
      query: `SELECT id,circleId,lotNo,alluminiumRate FROM transactionControlLots  where id = ${inputStates?.LotId}`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setLOTData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchAllPartAndServiceData = () => {
    let SQLQuery = {
      query: `SELECT ps.*, p.name AS partName FROM partsAndServices ps LEFT JOIN parts p ON ps.partId = p.id where ps.transformerId = ${inputStates?.capacityId}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setAllPartsData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  useEffect(() => {
    fetchCirclesData();
    fetchCapacityData();
  }, []);

  useEffect(() => {
    if (inputStates?.capacityId) fetchAllPartAndServiceData();
  }, [inputStates?.capacityId]);

  useEffect(() => {
    if (inputStates?.circleId) fetchAllLOTData();
  }, [inputStates?.circleId]);

  useEffect(() => {
    if (inputStates?.LotId && isNew) fetchLOTDataById();
    if (inputStates?.LotId && inputStates?.jobNo) fetchlotEstimated();
  }, [inputStates?.LotId, isNew, inputStates?.jobNo]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = parseFloat(value) || 0;

    // Extract values for calculations
    const { missing, damaged, weight, rate, partName } = updatedData[index];

    const quantity = ((missing + damaged) * (weight || 1)).toFixed(3);
    const amount = (quantity * rate).toFixed(3);
    const cgst = ((amount * 9) / 100).toFixed(3);
    const sgst = ((amount * 9) / 100).toFixed(3);
    const total = (
      parseFloat(amount) +
      parseFloat(cgst) +
      parseFloat(sgst)
    ).toFixed(3);

    let alRate = LOTData?.[0]?.alluminiumRate;

    // Calculate salvageAmount only when partName includes "Coils" or "Fittings"
    let salvageAmount = 0;
    if (
      partName &&
      (partName.includes("Coils") || partName.includes("Fittings"))
    ) {
      salvageAmount = (alRate * quantity).toFixed(3);
    } else {
      salvageAmount = (0).toFixed(3);
    }

    const slvgCgst = ((salvageAmount * 9) / 100).toFixed(3);
    const slvgSgst = ((salvageAmount * 9) / 100).toFixed(3);
    const totalSlvg = (
      parseFloat(salvageAmount) +
      parseFloat(slvgCgst) +
      parseFloat(slvgSgst)
    ).toFixed(3);

    const netAmount = (parseFloat(total) + parseFloat(totalSlvg)).toFixed(3);

    updatedData[index] = {
      ...updatedData[index],
      quantity,
      amount,
      cgst,
      sgst,
      total,
      salvageAmount,
      slvgCgst,
      slvgSgst,
      totalSlvg,
      netAmount,
    };

    setData(updatedData);
  };


  const handleSubmit = () => {
    const url = BASE_URL;

    // Utility function to replace NaN, undefined, or empty string with 0
    const sanitizeValue = (value) => {
      return value === undefined || value === "" || isNaN(value) ? 0 : value;
    };

    // Create the payload for POST requests (without id)
    const postPayload = data?.map((item) => ({
      lotId: sanitizeValue(inputStates?.LotId),
      jobNo: sanitizeValue(inputStates?.jobNo),
      capacityId: sanitizeValue(inputStates?.capacityId),
      make: inputStates?.make || "",
      siNo: sanitizeValue(inputStates?.siNo),
      mfgYear: sanitizeValue(inputStates?.mfgYear),
      repairNo: inputStates?.repairNo || "",
      economicReason: inputStates?.economicReason || "",
      totalCost: sanitizeValue(inputStates?.totalCost),

      partId: sanitizeValue(item.partId),
      missing: sanitizeValue(item.missing),
      damaged: sanitizeValue(item.damaged),
      weight: sanitizeValue(item.weight),
      quantity: sanitizeValue(item.quantity),
      rate: sanitizeValue(item.rate),
      amount: sanitizeValue(item.amount),
      cgst: sanitizeValue(item.cgst),
      sgst: sanitizeValue(item.sgst),
      total: sanitizeValue(item.total),
      salvageAmount: sanitizeValue(item.salvageAmount),
      slvgCgst: sanitizeValue(item.slvgCgst),
      slvgSgst: sanitizeValue(item.slvgSgst),
      totalSlvg: sanitizeValue(item.totalSlvg),
      netAmount: sanitizeValue(item.netAmount),
    }));

    // Create the payload for PATCH requests (with id)
    const patchPayload = data?.map((item) => ({
      ...postPayload.find((p) => p.partId === item.partId), // Get the sanitized values
      id: item.id, // Include the id only for PATCH
    }));

    // Check if not a new entry (edit case)
    if (!isNew) {
      Promise.all(
        patchPayload.map((item) =>
          axiosWithToken
            .patch(`${url}lotEstimated/${item.id}`, item) // Use item.id here
            .then((res) => {
              console.log(
                `Updated lotEstimated for id ${item.id} successfully!`
              );
            })
            .catch((error) => {
              console.error(`Failed to update id ${item.id}`, error);
              toast.error("Something Went Wrong!");
            })
        )
      ).then(() => {
        setTimeout(refresh, 500);
        toast("lotEstimated edit successfully!");
      });
    }

    // Handle the new entry case
    if (isNew) {
      axiosWithToken
        .post(url + "lotEstimated/bulk", postPayload) // Use postPayload here
        .then((res) => {
          setTimeout(refresh, 500);
          toast("lotEstimated created successfully!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchlotEstimated = (inpt) => {
    let SQLQuery = { query: `SELECT le.*, p.name AS partName, tcl.circleId, tt.storeIssueRate FROM lotEstimated le LEFT JOIN parts p ON le.partId = p.id LEFT JOIN transactionControlLots tcl ON le.lotId = tcl.id LEFT JOIN transformerTypes tt ON le.capacityId = tt.id WHERE le.lotId = ${inputStates?.LotId} and le.jobNo = ${inputStates?.jobNo}` };


    let url = BASE_URL;

    axiosWithToken
      .post(`${url}dynamic`, SQLQuery)
      .then((res) => {
        let dataObject = res.data;
        let dataObject1 = res.data?.[0];

        setInputStates((prevState) => ({
          ...prevState, // Keeps the other keys as is
          // jobNo: dataObject1?.jobNo,
          capacityId: dataObject1?.capacityId || "",
          make: dataObject1?.make || "",
          siNo: dataObject1?.siNo || "",
          mfgYear: dataObject1?.mfgYear || "",
          repairNo: dataObject1?.repairNo || "",
          economicReason: dataObject1?.economicReason || "",
          totalCost: dataObject1?.totalCost || "",
          siRate: dataObject1?.storeIssueRate || "",
          percentSIR: Number(dataObject1?.storeIssueRate) * 0.38 || "",
        }));
        
        
        if (Array.isArray(dataObject) && dataObject.length > 0) {
          setIsNew(false);
          setData(dataObject);
        } else {
          fetchAllPartAndServiceData()
          setIsNew(true);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (allPartsData && allPartsData.length > 0) {
      const updatedData = allPartsData.map((part) => ({
        ...part,
        missing: 0,
        damaged: 0,
        weight: 0,
        quantity: 0,
        rate: part.rate || 0,
        amount: 0,
        cgst: 0,
        sgst: 0,
        total: 0,
        salvageAmount: 0,
        slvgCgst: 0,
        slvgSgst: 0,
        totalSlvg: 0,
        netAmount: 0,
      }));
      setData(updatedData);
    }
  }, [allPartsData]);

  useEffect(() => {
    const totalSum = data.reduce(
      (acc, item) => acc + (Number(item.total) || 0),
      0
    );

    const formattedTotalCost =
      totalSum && !isNaN(totalSum) ? totalSum.toFixed(2) : 0.0;

    handleLotsChange("totalCost", formattedTotalCost);
  }, [data]);


  useEffect(() => {
    if (inputStates?.columnType == 1) setColumns(quantityColumns);
    if (inputStates?.columnType == 2) setColumns(amountColumns);
    if (inputStates?.columnType == 3) setColumns(detailColumns);
  }, [inputStates?.columnType]);

  return (
    <div>
      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          backgroundColor: "#f5f1f1",
          //   marginTop: "4px",
          display: "flex",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            width: "40%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>Circle Name</h6>
          <Autocomplete
            key={inputStates.circleId}
            options={circlesData}
            value={
              circlesData?.find((ele) => ele.id == inputStates.circleId) || null
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleLotsChange("circleId", value?.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" }, // Font size for input text
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" }, // Font size for input field
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "200px" }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Lot No
          </h6>

          <Autocomplete
            key={inputStates.LotId}
            options={allLOTData || []}
            value={
              allLOTData?.find((ele) => ele.id == inputStates.LotId) || null
            }
            getOptionLabel={(option) => option.lotNo}
            // onChange={(event, value) => handleLotsChange("LotId", value?.id)}
            onChange={(event, value) => {
              setInputStates((prevState) => ({
                ...prevState,
                LotId: value.id,
                capacityId: value.mostTransformersCapacity,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "100px" }}
          />

          <h6 style={{ fontSize: "13px", margin: 0 }}> Job No.</h6>
          <TextField
            name="jobNo"
            size="small"
            variant="outlined"
            value={inputStates.jobNo}
            onChange={(e) => handleLotsChange("jobNo", e.target.value)}
            style={{ width: "80px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
        </div>

        {/* Second Row */}
        <div
          style={{
            display: "flex",
            width: "60%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Rating
          </h6>
          <Autocomplete
            key={inputStates.capacityId}
            options={allLOTData || []}
            // disabled
            value={
              allLOTData?.find((ele) => ele.transformerTypesId == inputStates.capacityId) ||
              null
            }
            getOptionLabel={(option) => option.transformerCapacity}
            onChange={(event, value) => {
              handleLotsChange("capacityId", value?.transformerTypesId);
              handleLotsChange("siRate", value?.storeIssueRate);
              handleLotsChange(
                "percentSIR",
                Number(value?.storeIssueRate * 0.38)
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "150px" }}
          />

          <Button
            variant="contained"
            color={inputStates.columnType === 1 ? "primary" : "inherit"}
            onClick={() => handleLotsChange("columnType", 1)}
            style={{
              marginLeft: "10px",
              //   height: "30px",
              fontSize: "10px",
              padding: "5px 10px",
            }}
          >
            Quantities only
          </Button>
          <Button
            variant="contained"
            color={inputStates.columnType === 2 ? "primary" : "inherit"}
            onClick={() => handleLotsChange("columnType", 2)}
            style={{
              marginLeft: "10px",
              //   height: "30px",
              fontSize: "10px",
              padding: "5px 10px",
            }}
          >
            Quantity & Amount
          </Button>
          <Button
            variant="contained"
            color={inputStates.columnType === 3 ? "primary" : "inherit"}
            onClick={() => handleLotsChange("columnType", 3)}
            style={{
              marginLeft: "10px",
              //   height: "30px",
              fontSize: "10px",
              padding: "5px 10px",
            }}
          >
            Detailed View
          </Button>
        </div>
      </div>

      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          backgroundColor: "rgb(249 226 226)",
          //   marginTop: "4px",
          display: "flex",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "65%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>Make</h6>
          <TextField
              // key={inputStates.make}
            name="make"
            size="small"
            variant="outlined"
            value={inputStates.make}
            onChange={(e) => handleLotsChange("make", e.target.value)}
            style={{ width: "70px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
          <h6 style={{ fontSize: "13px", margin: 0 }}>SI NO.</h6>
          <TextField
          // key={inputStates.siNo}
            name="siNo"
            size="small"
            variant="outlined"
            value={inputStates.siNo}
            onChange={(e) => handleLotsChange("siNo", e.target.value)}
            style={{ width: "70px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
          <h6 style={{ fontSize: "13px", margin: 0 }}>Mfg Year</h6>
          <TextField
          // key={inputStates.mfgYear}
            name="mfgYear"
            size="small"
            variant="outlined"
            value={inputStates.mfgYear}
            onChange={(e) => handleLotsChange("mfgYear", e.target.value)}
            style={{ width: "70px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
          <h6 style={{ fontSize: "13px", margin: 0 }}>no of times repaired</h6>
          <TextField
          // key={inputStates.repairNo}
            name="repairNo"
            size="small"
            variant="outlined"
            value={inputStates.repairNo}
            onChange={(e) => handleLotsChange("repairNo", e.target.value)}
            style={{ width: "70px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
          <h6 style={{ fontSize: "13px", margin: 0 }}>TN No./ Store C</h6>
          <TextField
          // key={inputStates.tnNo}
            name="tnNo"
            size="small"
            variant="outlined"
            value={inputStates.tnNo}
            onChange={(e) => handleLotsChange("tnNo", e.target.value)}
            style={{ width: "70px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "35%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>SIR for New TR</h6>
          <TextField
          // key={inputStates.siRate}
            name="siRate"
            size="small"
            variant="outlined"
            // disabled
            value={inputStates.siRate}
            onChange={(e) => handleLotsChange("siRate", e.target.value)}
            style={{
              width: "100px",
              height: 30,
              fontSize: "13px",
              backgroundColor: "#d1d11b",
            }}
            InputProps={{
              style: {
                height: 30,
                fontSize: "13px",
                fontWeight: "bold",
                color: "black",
              }, // Set text color to black and bold
            }}
            inputProps={{
              style: { color: "black", fontWeight: "bold" }, // Force dark bold text even when disabled
            }}
          />

          <h6 style={{ fontSize: "13px", margin: 0 }}>38% Of SIR</h6>
          <TextField
          // key={inputStates.percentSIR}
            name="percentSIR"
            size="small"
            variant="outlined"
            value={inputStates.percentSIR}
            onChange={(e) => handleLotsChange("percentSIR", e.target.value)}
            style={{
              width: "100px",
              height: 30,
              fontSize: "13px",
              backgroundColor: "#d1d11b",
            }}
            InputProps={{
              style: {
                height: 30,
                fontSize: "13px",
                fontWeight: "bold",
                color: "black",
              }, // Set text color to black and bold
            }}
            inputProps={{
              style: { color: "black", fontWeight: "bold" }, // Force dark bold text even when disabled
            }}
          />
        </div>
      </div>

      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          backgroundColor:
            +inputStates.percentSIR >= +inputStates.totalCost
              ? "rgb(208, 245, 205)"
              : "rgb(235 149 149)",
          //   marginTop: "4px",
          display: "flex",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "90%",
            alignItems: "center",
            // gap: "10px",
            margin: "auto",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>
            Is Un Economical (Reason) ?
          </h6>
          <TextField
          // key={inputStates.economicReason}
            name="economicReason"
            size="small"
            variant="outlined"
            value={inputStates.economicReason}
            onChange={(e) => handleLotsChange("economicReason", e.target.value)}
            style={{ width: "170px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
          <h6 style={{ fontSize: "13px", margin: 0 }}>
            Total Cost : &nbsp; <b> {inputStates.totalCost}</b>
          </h6>
          <h6 style={{ fontSize: "13px", margin: 0 }}>
            Economical BY RS. : &nbsp;{" "}
            <b>
              {" "}
              {(+inputStates.percentSIR - +inputStates.totalCost || 0).toFixed(
                2
              )}
            </b>{" "}
          </h6>
          <h6 style={{ fontSize: "13px", margin: 0 }}>
            % Of SI Rate : &nbsp;{" "}
            <b>
              {" "}
              {(
                (+inputStates.totalCost / +inputStates.siRate) * 100 || 0
              ).toFixed(2)}
              %
            </b>
          </h6>
          <h6 style={{ fontSize: "13px", margin: 0 }}>
            Less By : &nbsp;{" "}
            <b>
              {" "}
              {(
                38 - (+inputStates.totalCost / +inputStates.siRate) * 100 || 0
              ).toFixed(2)}
              %
            </b>
          </h6>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: "collapse" }}>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    width: column.width || "auto",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: "2px solid black)", // Add border here
                  }} // Make headers bold
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.key} style={{ textAlign: "center" }}>
                      {column.key === "missing" ||
                      column.key === "damaged" ||
                      column.key === "weight" ? (
                        <TextField
                          type="number"
                          size="small"
                          variant="outlined"
                          value={row[column.key]}
                          onChange={(e) =>
                            handleInputChange(index, column.key, e.target.value)
                          }
                          style={{ width: "100px" }}
                          InputProps={{
                            sx: {
                              "& .MuiOutlinedInput-input": {
                                fontSize: "13px",
                                textAlign: "center",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            },
                          }}
                        />
                      ) : (
                        row[column.key]
                      )}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>

        {/* Pagination */}
        {/* <TablePagination
          rowsPerPageOptions={[
            25,
            50,
            100,
            { label: "All", value: data.length },
          ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          {!isNew ? "Update LOT" : "Save LOT"}
        </Button>
      </Toolbar>
    </div>
  );
};

export default EditEstimate;
