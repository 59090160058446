import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { useParams } from "react-router-dom";

const labelStyle = {
  fontSize: "16px",
  textAlign: "left",
  margin: 0,
};

const TransactionControlLots = () => {
  // State to hold the input values
  const [inputStates, setInputStates] = useState({
    circleId: null,
    lotNo: null,
    jobStartNo: null,
    noOfJobs: null,
    lastJobNo: null,
    oilOpeningBalance: null,
    trNotManufacturedBefore: "2010",
    coilWeightDeductionPercent: "15",
    chiefSignatoryDesignation: "S E (O&M)",
    AOsDesignation: "A O (O&M)",

    provideBiMetalConnectorHV: 0,
    provideBiMetalConnectorLV: 0,
    providePressureReleafValve: 0,
    provideDialTypeThermometer: 0,
  });

  const [circlesData, setCirclesData] = useState([]);
  const [capacityData, setCapacityData] = useState([]);
  const params = useParams();

  // General handler to update state for both TextField and Autocomplete
  const handleLotsChange = (key, value) => {
    setInputStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Checkbox handler
  const handleCheckboxChange = (key, checked) => {
    handleLotsChange(key, checked ? 1 : 0);
  };

  const fetchTransactionControlLots = (inpt) => {
    let url = BASE_URL;

    axiosWithToken
      .get(`${url}transactionControlLots/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        // List of date keys to format
        const dateKeys = [
          "transformerReceiptDate",
          "inspectionDate",
          "economicTimeDate",
          "acosGatePassDate",
          "oilGatePassDate",
        ];

        // Format date values to YYYY-MM-DD
        const formattedDataObject = Object.keys(dataObject).reduce(
          (acc, key) => {
            if (dateKeys.includes(key) && dataObject[key]) {
              acc[key] = moment(dataObject[key]).format("YYYY-MM-DD");
            } else {
              acc[key] = dataObject[key];
            }
            return acc;
          },
          {}
        );

        // Combine formatted dates with existing inputStates
        let newData = {
          ...inputStates,
          ...formattedDataObject,
        };

        setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    // Destructure date values from inputStates
    const {
      lastJobNo,
      // trNotManufacturedBefore,
      transformerReceiptDate,
      inspectionDate,
      economicTimeDate,
      acosGatePassDate,
      oilGatePassDate,
      ...remainingState
    } = inputStates;

    // Format the date values to YYYY-MM-DD
    const formattedDates = {
      transformerReceiptDate: transformerReceiptDate
        ? moment(transformerReceiptDate).format("YYYY-MM-DD")
        : null,
      inspectionDate: inspectionDate
        ? moment(inspectionDate).format("YYYY-MM-DD")
        : null,
      economicTimeDate: economicTimeDate
        ? moment(economicTimeDate).format("YYYY-MM-DD")
        : null,
      acosGatePassDate: acosGatePassDate
        ? moment(acosGatePassDate).format("YYYY-MM-DD")
        : null,
      oilGatePassDate: oilGatePassDate
        ? moment(oilGatePassDate).format("YYYY-MM-DD")
        : null,
    };

    // Combine formatted dates with remaining state
    const payload = { ...remainingState, ...formattedDates };

    if (params.id) {
      axiosWithToken
        .patch(url + `transactionControlLots/${params.id}`, payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("TransactionControlLots edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "transactionControlLots", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("TransactionControlLots created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchCirclesData = () => {
    let SQLQuery = { query: `select id, name  from circles where alloted = 1` };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setCirclesData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchCapacityData = () => {
    let SQLQuery = {
      query: `select id, transformerCapacity  from transformerTypes`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setCapacityData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (inputStates?.jobStartNo && inputStates?.noOfJobs) {
      const lastJobNoIs = +inputStates.jobStartNo + (+inputStates.noOfJobs - 1);
      setInputStates((prevState) => ({
        ...prevState,
        lastJobNo: lastJobNoIs,
      }));
    }
  }, [inputStates?.jobStartNo, inputStates?.noOfJobs]);

  useEffect(() => {
    let circleName = circlesData?.find(
      (ele) => ele.id == inputStates?.circleId
    )?.name;

    if (inputStates?.circleId && circleName) {
      setInputStates((prevState) => ({
        ...prevState,
        chiefSignatoryDetaila: ` AWNL, ${circleName}`,
        accountOfficerDetail: ` AWNL, ${circleName}`,
        inspectorDetails1: ` AWNL, ${circleName}`,
        inspectorDetails2: ` AWNL, ${circleName}`,
        cosignee: ` THE ACOS , AVVNL, ${circleName}`,
      }));
    }
  }, [inputStates?.circleId]);

  useEffect(() => {
    if (params.id) fetchTransactionControlLots();
  }, [params.id]);

  useEffect(() => {
    fetchCirclesData();
    fetchCapacityData();
  }, []);

  return (
    <div>
      <div
        style={{
          border: "1px solid gray",
          width: "60%",
          margin: "auto",
          backgroundColor: "#e9c2e9",
        }}
      >
        <h6 style={{ fontSize: "18px" }}>
          Be careful while filling No. of Jobs, it may result in wrong reports
        </h6>
      </div>

      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          backgroundColor: "#f5f1f1",
          marginTop: "4px",
          display: "flex",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            width: "40%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>Circle Name</h6>
          <Autocomplete
            key={inputStates.circleId}
            options={circlesData}
            value={
              circlesData?.find((ele) => ele.id == inputStates.circleId) || null
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleLotsChange("circleId", value?.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" }, // Font size for input text
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" }, // Font size for input field
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "200px" }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Lot No
          </h6>
          <TextField
            name="lotNo"
            size="small"
            variant="outlined"
            value={inputStates.lotNo}
            onChange={(e) => handleLotsChange("lotNo", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
        </div>

        {/* Second Row */}
        <div
          style={{
            display: "flex",
            width: "60%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>Starting Job No</h6>
          <TextField
            name="jobStartNo"
            size="small"
            variant="outlined"
            value={inputStates.jobStartNo}
            onChange={(e) => handleLotsChange("jobStartNo", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            No. of Jobs
          </h6>
          <TextField
            name="noOfJobs"
            size="small"
            variant="outlined"
            value={inputStates.noOfJobs}
            onChange={(e) => handleLotsChange("noOfJobs", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Last Job No
          </h6>
          <TextField
            name="lastJobNo"
            disabled
            size="small"
            variant="outlined"
            value={inputStates.lastJobNo}
            onChange={(e) => handleLotsChange("lastJobNo", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Oil Opening Balance
          </h6>
          <TextField
            name="oilOpeningBalance"
            size="small"
            variant="outlined"
            value={inputStates.oilOpeningBalance}
            onChange={(e) =>
              handleLotsChange("oilOpeningBalance", e.target.value)
            }
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "40%", margin: "auto", marginTop: "10px" }}>
          <table style={{ pageBreakInside: "avoid", width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "70%",
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Date of receipt of Transformers
                  </h6>{" "}
                </td>

                <td style={{ width: "30%", border: "none" }}>
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <input
                      type="date"
                      onChange={(e) =>
                        handleLotsChange(
                          "transformerReceiptDate",
                          e.target.value
                        )
                      }
                      style={{
                        width: "100px",
                        height: "30px",
                        fontSize: "13px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "4px",
                        appearance: "none",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      placeholder="DD/MM/YYYY"
                      value={inputStates.transformerReceiptDate || null}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Inspection Date
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    {/* <TextField
                      name="inspectionDate"
                      size="small"
                      variant="outlined"
                      value={inputStates.inspectionDate}
                      onChange={(e) =>
                        handleLotsChange("inspectionDate", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    /> */}

                    <input
                      type="date"
                      onChange={(e) =>
                        handleLotsChange("inspectionDate", e.target.value)
                      }
                      style={{
                        width: "100px",
                        height: "30px",
                        fontSize: "13px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "4px",
                        appearance: "none",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      placeholder="DD/MM/YYYY"
                      value={inputStates.inspectionDate || null}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Economic Time Date
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <input
                      type="date"
                      onChange={(e) =>
                        handleLotsChange("economicTimeDate", e.target.value)
                      }
                      style={{
                        width: "100px",
                        height: "30px",
                        fontSize: "13px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "4px",
                        appearance: "none",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      placeholder="DD/MM/YYYY"
                      value={inputStates.economicTimeDate || null}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    ALUMINIUM Rate as per Economic Times
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="alluminiumRate"
                      size="small"
                      variant="outlined"
                      value={inputStates.alluminiumRate}
                      onChange={(e) =>
                        handleLotsChange("alluminiumRate", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    COPPER Rate as per Economic Times
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="copperRate"
                      size="small"
                      variant="outlined"
                      value={inputStates.copperRate}
                      onChange={(e) =>
                        handleLotsChange("copperRate", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Weight Deduction % of COILS
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="coilWeightDeductionPercent"
                      size="small"
                      variant="outlined"
                      value={inputStates.coilWeightDeductionPercent}
                      onChange={(e) =>
                        handleLotsChange(
                          "coilWeightDeductionPercent",
                          e.target.value
                        )
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Brass Metal Parts Rate
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="brassMetalPartRate"
                      size="small"
                      variant="outlined"
                      value={inputStates.brassMetalPartRate}
                      onChange={(e) =>
                        handleLotsChange("brassMetalPartRate", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    HT Metal Part average weight
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="htMetalPartAvgWeight"
                      size="small"
                      variant="outlined"
                      value={inputStates.htMetalPartAvgWeight}
                      onChange={(e) =>
                        handleLotsChange("htMetalPartAvgWeight", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    LT Metal Part average weight
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="ltMetalPartAvgWeight"
                      size="small"
                      variant="outlined"
                      value={inputStates.ltMetalPartAvgWeight}
                      onChange={(e) =>
                        handleLotsChange("ltMetalPartAvgWeight", e.target.value)
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Transformers not manufactured before
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="trNotManufacturedBefore"
                      size="small"
                      variant="outlined"
                      value={inputStates.trNotManufacturedBefore}
                      onChange={(e) =>
                        handleLotsChange(
                          "trNotManufacturedBefore",
                          e.target.value
                        )
                      }
                      style={{ width: "100px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                marginLeft: "auto",
                justifyContent: "flex-end",
              }}
            >
              <h6
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  margin: 0,
                }}
              >
                Oil Supplied by
              </h6>
              <Button
                variant="contained"
                color={inputStates.oilSuppliedBy === 1 ? "primary" : "inherit"}
                onClick={() => handleLotsChange("oilSuppliedBy", 1)}
                style={{
                  marginLeft: "10px",
                  height: "30px",
                  padding: "5px 10px",
                }}
              >
                Contractor
              </Button>
              <Button
                variant="contained"
                color={inputStates.oilSuppliedBy === 2 ? "primary" : "inherit"}
                onClick={() => handleLotsChange("oilSuppliedBy", 2)}
                style={{
                  marginLeft: "10px",
                  height: "30px",
                  padding: "5px 10px",
                }}
              >
                AVVNL
              </Button>
            </div>
          </div>

          {inputStates.oilSuppliedBy === 2 && (
            <div
              style={{
                justifyContent: "flex-end",
                border: "1px solid gray",
                marginTop: "5px",
                marginRight: "-70px",
                padding: "10px",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "none",
                        width: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        No. of OIL Drums received
                      </h6>{" "}
                    </td>

                    <td
                      style={{
                        border: "none",
                        width: "40%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          fontSize: "13px",
                        }}
                      >
                        <TextField
                          name="noOfOilDrumsReceived"
                          size="small"
                          variant="outlined"
                          value={inputStates.noOfOilDrumsReceived}
                          onChange={(e) =>
                            handleLotsChange(
                              "noOfOilDrumsReceived",
                              e.target.value
                            )
                          }
                          style={{
                            width: "200px",
                            height: 30,
                            fontSize: "13px",
                          }}
                          InputProps={{
                            style: { height: 30, fontSize: "13px" },
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        Quantity of OIL received (Ltrs)
                      </h6>{" "}
                    </td>

                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          fontSize: "13px",
                        }}
                      >
                        <TextField
                          name="QuantityOfOilReceived"
                          size="small"
                          variant="outlined"
                          value={inputStates.QuantityOfOilReceived}
                          onChange={(e) =>
                            handleLotsChange(
                              "QuantityOfOilReceived",
                              e.target.value
                            )
                          }
                          style={{
                            width: "200px",
                            height: 30,
                            fontSize: "13px",
                          }}
                          InputProps={{
                            style: { height: 30, fontSize: "13px" },
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        Oil Received from
                      </h6>{" "}
                    </td>

                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          fontSize: "13px",
                        }}
                      >
                        <TextField
                          name="oilReceivedFrom"
                          size="small"
                          variant="outlined"
                          value={inputStates.oilReceivedFrom}
                          onChange={(e) =>
                            handleLotsChange("oilReceivedFrom", e.target.value)
                          }
                          style={{
                            width: "200px",
                            height: 30,
                            fontSize: "13px",
                          }}
                          InputProps={{
                            style: { height: 30, fontSize: "13px" },
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        OIL Gate Pass No.
                      </h6>{" "}
                    </td>

                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          fontSize: "13px",
                        }}
                      >
                        <TextField
                          name="oilGatePassNo"
                          size="small"
                          variant="outlined"
                          value={inputStates.oilGatePassNo}
                          onChange={(e) =>
                            handleLotsChange("oilGatePassNo", e.target.value)
                          }
                          style={{
                            width: "200px",
                            height: 30,
                            fontSize: "13px",
                          }}
                          InputProps={{
                            style: { height: 30, fontSize: "13px" },
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        OIL Gate Pass Date
                      </h6>{" "}
                    </td>

                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          fontSize: "13px",
                        }}
                      >
                        <input
                          type="date"
                          onChange={(e) =>
                            handleLotsChange("oilGatePassDate", e.target.value)
                          }
                          style={{
                            width: "200px",
                            height: "30px",
                            fontSize: "13px",
                            border: "1px solid #d3d3d3",
                            borderRadius: "4px",
                            appearance: "none",
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          placeholder="DD/MM/YYYY"
                          value={inputStates.oilGatePassDate || null}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div style={{ width: "50%", margin: "auto" }}>
          <div
            style={{
              border: "1px solid gray",
              width: "100%",
              margin: "auto",
              backgroundColor: "#f5f1f1",
              marginTop: "4px",
              display: "flex",
              padding: "6px",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <h6 style={{ fontSize: "18px", margin: 0 }}>
              MOST Transformers are of{" "}
            </h6>
            <Autocomplete
              key={inputStates.mostTransformersCapacity}
              options={capacityData}
              value={
                capacityData?.find(
                  (ele) => ele.id == inputStates.mostTransformersCapacity
                ) || null
              }
              getOptionLabel={(option) => option.transformerCapacity}
              onChange={(event, value) =>
                handleLotsChange("mostTransformersCapacity", value?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "18px",
                      alignItems: "center",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { height: 30, fontSize: "18px" },
                  }}
                />
              )}
              sx={{
                "& .MuiAutocomplete-option": {
                  fontSize: "18px",
                },
              }}
              style={{ minWidth: "150px" }}
            />
            <h6 style={{ fontSize: "18px", margin: 0 }}>CAPACITY </h6>
          </div>

          <div
            style={{
              marginRight: "50px",
              marginBottom: "15px",
              marginTop: "10px",
            }}
          >
            <Typography variant="h6" style={labelStyle}>
              The changes made here will automatically affect the whole Lot you
              selected
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputStates.provideBiMetalConnectorHV === 1}
                    onChange={(e) =>
                      handleCheckboxChange(
                        "provideBiMetalConnectorHV",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h6" style={labelStyle}>
                    Provide Bi-Metal Connector HV?
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputStates.provideBiMetalConnectorLV === 1}
                    onChange={(e) =>
                      handleCheckboxChange(
                        "provideBiMetalConnectorLV",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h6" style={labelStyle}>
                    Provide Bi-Metal Connector LV?
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputStates.providePressureReleafValve === 1}
                    onChange={(e) =>
                      handleCheckboxChange(
                        "providePressureReleafValve",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h6" style={labelStyle}>
                    Provide Pressure Relief Valve?
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputStates.provideDialTypeThermometer === 1}
                    onChange={(e) =>
                      handleCheckboxChange(
                        "provideDialTypeThermometer",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h6" style={labelStyle}>
                    Provide Dial Type Thermometer?
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <table style={{ pageBreakInside: "avoid", width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "45%",
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Chief Signatory's Designation
                  </h6>{" "}
                </td>

                <td style={{ width: "40%", border: "none" }}>
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="chiefSignatoryDesignation"
                      size="small"
                      variant="outlined"
                      value={inputStates.chiefSignatoryDesignation}
                      onChange={(e) =>
                        handleLotsChange(
                          "chiefSignatoryDesignation",
                          e.target.value
                        )
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Chief Signatory's Details
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="chiefSignatoryDetaila"
                      size="small"
                      variant="outlined"
                      value={inputStates.chiefSignatoryDetaila}
                      onChange={(e) =>
                        handleLotsChange(
                          "chiefSignatoryDetaila",
                          e.target.value
                        )
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    A O's Designation
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="AOsDesignation"
                      size="small"
                      variant="outlined"
                      value={inputStates.AOsDesignation}
                      onChange={(e) =>
                        handleLotsChange("AOsDesignation", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Account Officer's Details
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="accountOfficerDetail"
                      size="small"
                      variant="outlined"
                      value={inputStates.accountOfficerDetail}
                      onChange={(e) =>
                        handleLotsChange("accountOfficerDetail", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Inspector's Name 1
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="inspectorName1"
                      size="small"
                      variant="outlined"
                      value={inputStates.inspectorName1}
                      onChange={(e) =>
                        handleLotsChange("inspectorName1", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Inspector's Details 1
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="inspectorDetails1"
                      size="small"
                      variant="outlined"
                      value={inputStates.inspectorDetails1}
                      onChange={(e) =>
                        handleLotsChange("inspectorDetails1", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Inspector's Name 2
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="inspectorName2"
                      size="small"
                      variant="outlined"
                      value={inputStates.inspectorName2}
                      onChange={(e) =>
                        handleLotsChange("inspectorName2", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Inspector's Details 2
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="inspectorDetails2"
                      size="small"
                      variant="outlined"
                      value={inputStates.inspectorDetails2}
                      onChange={(e) =>
                        handleLotsChange("inspectorDetails2", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    ACOS Gate Pase No
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="acosGatePassNo"
                      size="small"
                      variant="outlined"
                      value={inputStates.acosGatePassNo}
                      onChange={(e) =>
                        handleLotsChange("acosGatePassNo", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    ACOS Gate Pase Date
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <input
                      type="date"
                      onChange={(e) =>
                        handleLotsChange("acosGatePassDate", e.target.value)
                      }
                      style={{
                        width: "250px",
                        height: "30px",
                        fontSize: "13px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "4px",
                        appearance: "none",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      placeholder="DD/MM/YYYY"
                      value={inputStates.acosGatePassDate || null}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Consignee
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="cosignee"
                      size="small"
                      variant="outlined"
                      value={inputStates.cosignee}
                      onChange={(e) =>
                        handleLotsChange("cosignee", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Paying Authority
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="payingAuthority"
                      size="small"
                      variant="outlined"
                      value={inputStates.payingAuthority}
                      onChange={(e) =>
                        handleLotsChange("payingAuthority", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    Consigner
                  </h6>{" "}
                </td>

                <td
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                      fontSize: "13px",
                    }}
                  >
                    <TextField
                      name="cosigner"
                      size="small"
                      variant="outlined"
                      value={inputStates.cosigner}
                      onChange={(e) =>
                        handleLotsChange("cosigner", e.target.value)
                      }
                      style={{ width: "250px", height: 30, fontSize: "13px" }}
                      InputProps={{
                        style: { height: 30, fontSize: "13px" },
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          {params.id ? "Update LOT" : "Save LOT"}
        </Button>
      </Toolbar>
    </div>
  );
};

export default TransactionControlLots;
