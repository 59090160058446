import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import Tables from "../../utils/components/tables";
import html2pdf from "html2pdf.js";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "jobNo",
    label: "Job No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "kva",
    label: "KVA",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "siNo",
    label: "S. NO",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "DM",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
  {
    id: "wound",
    label: "Al/Cu",
    align: "left",
    minWidth: 40,
  },
];

const JoinInspection = () => {
  const [inputStates, setInputStates] = useState({
    columnType: 3,
    siRate: 0,
    percentSIR: 0,
  });

  const [circlesData, setCirclesData] = useState([]);
  const [allLOTData, setAllLOTData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [finalData, setFinalData] = React.useState([]);
  const [mergedData, setMergedData] = React.useState([]);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT le.*, tcl.circleId, tt.transformerCapacity, tt.kva, tt.wound, p.name FROM lotEstimated le LEFT JOIN transactionControlLots tcl ON le.lotId = tcl.id LEFT JOIN transformerTypes tt ON le.capacityId = tt.id LEFT JOIN parts p ON le.partId = p.id WHERE le.lotId = ${inputStates?.LotId} AND le.jobNo BETWEEN ${inputStates?.fromJobNo} AND ${inputStates?.toJobNo} ${whereConditions}  order by le.id DESC `,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM lotEstimated le LEFT JOIN transactionControlLots tcl ON le.lotId = tcl.id LEFT JOIN transformerTypes tt ON le.capacityId = tt.id LEFT JOIN parts p ON le.partId = p.id WHERE le.lotId = ${inputStates?.LotId} AND le.jobNo BETWEEN ${inputStates?.fromJobNo} AND ${inputStates?.toJobNo}  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  // General handler to update state for both TextField and Autocomplete
  const handleLotsChange = (key, value) => {
    setInputStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchCirclesData = () => {
    let SQLQuery = { query: `select id, name  from circles where alloted = 1` };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setCirclesData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  const fetchAllLOTData = () => {
    let SQLQuery = {
      query: `SELECT tcl.id, tcl.circleId, tcl.lotNo, tcl.mostTransformersCapacity, tt.storeIssueRate, tt.id as transformerTypesId,  tt.transformerCapacity FROM transactionControlLots tcl LEFT JOIN transformerTypes tt ON CAST(tcl.mostTransformersCapacity AS UNSIGNED) = tt.id WHERE tcl.circleId = ${inputStates?.circleId}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        setAllLOTData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  };

  function mergeDataByLotIdAndJobNo(data) {
    const resultArray = [];

    // Add the new common keys: 'kva', 'capacityId', 'wound'
    const commonKeys = [
      "lotId",
      "jobNo",
      "make",
      "siNo",
      "mfgYear",
      "repairNo",
      "economicReason",
      "kva",
      "capacityId",
      "wound",
    ];

    data.forEach((item) => {
      // Check if an object with the same lotId and jobNo already exists
      const existingEntry = resultArray.find(
        (entry) => entry.lotId === item.lotId && entry.jobNo === item.jobNo
      );

      if (!existingEntry) {
        // If no existing entry is found, create a new object for this lotId and jobNo
        const newObject = {};

        // Copy over common keys and their values
        commonKeys.forEach((key) => {
          if (item[key] !== undefined) {
            newObject[key] = item[key];
          }
        });

        // Add other keys with partId appended
        Object.keys(item).forEach((itemKey) => {
          if (!commonKeys.includes(itemKey) && itemKey !== "partId") {
            const newKey = `${itemKey}_${item.partId}`;
            newObject[newKey] = item[itemKey];
          }
        });

        resultArray.push(newObject);
      } else {
        // If an existing entry is found, just append new key-value pairs for this partId
        Object.keys(item).forEach((itemKey) => {
          if (!commonKeys.includes(itemKey) && itemKey !== "partId") {
            const newKey = `${itemKey}_${item.partId}`;
            existingEntry[newKey] = item[itemKey];
          }
        });
      }
    });

    return resultArray;
  }

  const downloadPDF = () => {
    const element = document.getElementById("table-to-pdf");
    const opt = {
      margin: 0.1,
      filename: "table.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    };
    html2pdf().from(element).set(opt).save();
  };

  useEffect(() => {
    const mergedData = mergeDataByLotIdAndJobNo(finalData);
    setMergedData(mergedData);
    console.log("mergedData", mergedData);
  }, [finalData]);

  useEffect(() => {
    fetchCirclesData();
  }, []);

  useEffect(() => {
    if (inputStates?.circleId) fetchAllLOTData();
  }, [inputStates?.circleId]);

  return (
    <div>
      <div
        style={{
          border: "1px solid gray",
          width: "100%",
          margin: "auto",
          backgroundColor: "#f5f1f1",
          //   marginTop: "4px",
          display: "flex",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "space-between",
          margin: "auto",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            width: "80%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h6 style={{ fontSize: "13px", margin: 0 }}>Circle Name</h6>
          <Autocomplete
            key={inputStates.circleId}
            options={circlesData}
            value={
              circlesData?.find((ele) => ele.id == inputStates.circleId) || null
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleLotsChange("circleId", value?.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" }, // Font size for input text
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" }, // Font size for input field
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "200px" }}
          />

          <h6 style={{ fontSize: "13px", marginLeft: "10px", margin: 0 }}>
            Lot No
          </h6>

          <Autocomplete
            key={inputStates.LotId}
            options={allLOTData || []}
            value={
              allLOTData?.find((ele) => ele.id == inputStates.LotId) || null
            }
            getOptionLabel={(option) => option.lotNo}
            // onChange={(event, value) => handleLotsChange("LotId", value?.id)}
            onChange={(event, value) => {
              setInputStates((prevState) => ({
                ...prevState,
                LotId: value.id,
                capacityId: value.mostTransformersCapacity,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { height: 30, fontSize: "13px" },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-option": {
                fontSize: "13px", // Font size for options
              },
            }}
            style={{ minWidth: "100px" }}
          />

          <h6 style={{ fontSize: "13px", margin: 0 }}> From Job No.</h6>
          <TextField
            name="fromJobNo"
            size="small"
            variant="outlined"
            value={inputStates.fromJobNo}
            onChange={(e) => handleLotsChange("fromJobNo", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />

          <h6 style={{ fontSize: "13px", margin: 0 }}> To Job No.</h6>
          <TextField
            name="toJobNo"
            size="small"
            variant="outlined"
            value={inputStates.toJobNo}
            onChange={(e) => handleLotsChange("toJobNo", e.target.value)}
            style={{ width: "60px", height: 30, fontSize: "13px" }}
            InputProps={{
              style: { height: 30, fontSize: "13px" },
            }}
          />

          <Button
            variant="contained"
            onClick={() => {
              fetchFinalData();
              getTotalRows();
            }}
            style={{
              marginLeft: "10px",
              //   height: "30px",
              fontSize: "10px",
              padding: "5px 10px",
            }}
          >
            Prepare Data
          </Button>

          <Button
            variant="contained"
            onClick={downloadPDF}
            style={{
              marginLeft: "10px",
              //   height: "30px",
              fontSize: "10px",
              padding: "5px 10px",
            }}
          >
            Download PDF
          </Button>
        </div>
      </div>

      <br />

      {/* <Button variant="contained" color="primary" onClick={downloadPDF} style={{ marginBottom: '20px' }}>
        Download PDF
      </Button> */}

      <TableContainer
        component={Paper}
        className="tableContainer"
        id="table-to-pdf"
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={3} className="headerCell">
                S. No
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Job No.
              </TableCell>
              <TableCell colSpan={6} className="headerCell">
                Transformer Particulars
              </TableCell>
              <TableCell colSpan={4} className="headerCell">
                HV Coil
              </TableCell>
              <TableCell colSpan={4} className="headerCell">
                LV Coil
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                New Tr. Oil Qty (Ltrs)
              </TableCell>
              <TableCell colSpan={4} className="headerCell">
                Bushing
              </TableCell>
              <TableCell colSpan={4} className="headerCell">
                Rod Fittings
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Sundry Item
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Gasket
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Breather
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Dial Type Tmr
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                Oil Drain Valve
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                P.R. Valve
              </TableCell>
              <TableCell colSpan={2} rowSpan={2} className="headerCell">
                Bi Metal Connector
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                M&P Unit
              </TableCell>
              <TableCell rowSpan={3} className="headerCell">
                E / U
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2} className="headerCell">
                KVA
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                A1/Cu
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                Make
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                S. No.
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                Mfg. Year
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                T. N No
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                DM
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                Wght Kg P Coil
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                Total weight
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                DM
              </TableCell>
              <TableCell rowSpan={2} className="headerCell">
                Wght Kg P Coil
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                Total weight
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                HV
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                LV
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                HV
              </TableCell>
              <TableCell colSpan={2} className="headerCell">
                LV
              </TableCell>
              <TableCell colSpan={1} rowSpan={2} className="headerCell">
                MS
              </TableCell>
              <TableCell colSpan={1} rowSpan={2} className="headerCell">
                DM
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="headerCell">Al</TableCell>
              <TableCell className="headerCell">Cu</TableCell>
              <TableCell className="headerCell">Al</TableCell>
              <TableCell className="headerCell">Cu</TableCell>
              <TableCell className="headerCell">MS</TableCell>
              <TableCell className="headerCell">DM</TableCell>
              <TableCell className="headerCell">MS</TableCell>
              <TableCell className="headerCell">DM</TableCell>
              <TableCell className="headerCell">MS</TableCell>
              <TableCell className="headerCell">DM</TableCell>
              <TableCell className="headerCell">MS</TableCell>
              <TableCell className="headerCell">DM</TableCell>
              <TableCell className="headerCell">HT</TableCell>
              <TableCell className="headerCell">LT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {[...Array(36).keys()].map((i) => (
                <TableCell key={i} className="bodyCell">
                  {i + 1}
                </TableCell>
              ))}
            </TableRow>
            {mergedData?.map((ele, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell className="bodyCell">{index + 1}</TableCell>
                    <TableCell className="bodyCell">{ele.jobNo}</TableCell>
                    <TableCell className="bodyCell">{ele.kva}</TableCell>
                    <TableCell className="bodyCell">{ele.wound}</TableCell>
                    <TableCell className="bodyCell">{ele.make}</TableCell>
                    <TableCell className="bodyCell">{ele.siNo}</TableCell>
                    <TableCell className="bodyCell">{ele.mfgYear}</TableCell>
                    <TableCell className="bodyCell">{ele.tnNo}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_5}</TableCell>
                    <TableCell className="bodyCell">{ele.weight_5}</TableCell>
                    <TableCell className="bodyCell">
                      {ele.quantity_5 < 315 ? ele.quantity_5 : ""}
                    </TableCell>

                    <TableCell className="bodyCell">
                      {ele.quantity_5 >= 315 ? ele.quantity_5 : ""}
                    </TableCell>

                    <TableCell className="bodyCell">{ele.damaged_6}</TableCell>
                    <TableCell className="bodyCell">{ele.weight_6}</TableCell>
                    <TableCell className="bodyCell">
                      {ele.quantity_6 < 315 ? ele.quantity_6 : ""}
                    </TableCell>

                    <TableCell className="bodyCell">
                      {ele.quantity_6 >= 315 ? ele.quantity_6 : ""}
                    </TableCell>
                    <TableCell className="bodyCell">{ele.quantity_7}</TableCell>
                    <TableCell className="bodyCell">{ele.missing_8}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_8}</TableCell>
                    <TableCell className="bodyCell">{ele.missing_9}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_9}</TableCell>
                    <TableCell className="bodyCell">{ele.missing_10}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_10}</TableCell>
                    <TableCell className="bodyCell">{ele.missing_11}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_11}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_12}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_13}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_14}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_15}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_19}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_16}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_18}</TableCell>
                    <TableCell className="bodyCell">{ele.quantity_17}</TableCell>
                    <TableCell className="bodyCell">{ele.missing_4}</TableCell>
                    <TableCell className="bodyCell">{ele.damaged_4}</TableCell>
                    <TableCell className="bodyCell">E</TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>

        {/* CSS for the table */}
        <style>
          {`
          .tableContainer {
            padding: 20px;
            max-width: 100%;
            overflow-x: auto;
          }

          .table {
            width: 100%;
            border-collapse: collapse;
          }

          .headerCell {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
            background-color: #f2f2f2;
            font-weight: bold;
          }

          .bodyCell {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
          }
        `}
        </style>
      </TableContainer>
    </div>
  );
};

export default JoinInspection;
