import React, { useEffect } from "react";
import { Autocomplete, Button, Grid, Paper, Toolbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const EditWeightAndDeduction = () => {
  const [inputStates, setInputStates] = React.useState({});
  const params = useParams();

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `salvageWeightDeductions/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("WeightAndDeduction edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "salvageWeightDeductions", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("WeightAndDeduction created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchsalvageWeightDeductions = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `salvageWeightDeductions/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchsalvageWeightDeductions();
  }, [params.id]);

  const inputFields = [
    {
      type: "Autocomplete",
      label: "Part Name",
      stateKey: "partId",
      valueKey: "id",
      options: "parts",
      getOptionLabelKey: "name",
    },
    { type: "TextField", label: "Ai Scrap Rate", stateKey: "aiScrapRate" },
    { type: "TextField", label: "Cu Scrap Rate", stateKey: "cuScrapRate" },
    { type: "TextField", label: "Brass Scrap Rate", stateKey: "brassScrapRate" },
    {
      type: "TextField",
      label: "Detection % Towards Impurities",
      stateKey: "deductionPercentage",
    },
    { type: "TextField", label: "Damage Metal Part Weight", stateKey: "damagedPartWeight" },
    { type: "TextField", label: "CGST Rate", stateKey: "csgt" },
    { type: "TextField", label: "SGST Rate", stateKey: "sgst" },
    { type: "TextField", label: "IGST Rate", stateKey: "igst" },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
        />

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Paper>
    </>
  );
};

export default EditWeightAndDeduction;
